export default {
  get_all_mondays: function * (year){
    var d = new Date(Date.UTC(year, 0, 1));
    if (d.getUTCDay() == 1 )  {
      yield new Date(d);
    }
    d.setUTCDate((d.getUTCDate() + 8 - d.getUTCDay()) % 7);
 
    while (d.getUTCFullYear() == year){
      yield new Date(d);
      d.setUTCDate(d.getUTCDate() + 7);
    }
   }
}
