<!--
Table of student data over a year. Used in star chart, payment chart, and kiosk chart
-->

<template>
    <div>
        <div class="container-fluid">
        <transition name="fade">
        <div v-if="is_loaded" class="row">
            <div id="chartContainer" class="col-lg-12">
                <table class="table table-striped table-sm table-hover table-bordered chartTable">
                <thead class="thead-dark">
                    <tr>
                    <th></th>
                    <th v-for="week in weeks" :key="week.getTime()" scope="col" class="cellDate" v-bind:class= "{recitalCell: recitals[week.getTime()]}">
                    {{ week.getUTCMonth() + 1 }}/{{ week.getUTCDate() }}
                    </th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="student in sorted_students" :key="student.id">
                    <th scope="col" class="align-middle cellName py-0">
                        {{student.name}} 
                    </th>

                    <component :is="cell"
                                v-for="week in weeks" 
                                :key="student.id + '-' + week.getTime()"
                                :week="week"
                                :student="student"
                                :data="{lesson: lessons[student.id] ? lessons[student.id][week.getTime()] : null}"

                                :is_recital="recitals[week.getTime()] != null"
                                :star_size="star_size"
                                @on-click="onCellClick">
                    </component>

                    </tr>
                </tbody>
                </table>
            </div>
            <div class="col-lg-12">
                <input type="checkbox" v-model="active_only"> 
                <span> Show only active students</span>
            </div>
        </div>
        </transition>
        <transition name="fade">
            <div v-if="!is_loaded" class="loading">
            <font-awesome-icon icon="circle-notch" size="3x" spin style="color:white"/>
            </div>
        </transition>
        </div>
    </div>
</template>

<script>
import dates from "../date_utils.js";
import { ApiFactory } from "../api/ApiFactory";
const StudentAPI = ApiFactory.get("students");
const RecitalsAPI = ApiFactory.get("recitals");
const LessonAPI = ApiFactory.get("lessons");
var _ = require('lodash');


export default {
    name: 'ChartTable',
    props: {
        cell: {
            type: [String, Object],
            default: 'td',
        },

        year: {
            type: Number,
            default: new Date().getFullYear()
        },

        get_lessons: {
            type: Boolean,
            default: false
        },

        star_size:{
          type: [String],
            default: '1x',
        }
    },
    data(){
        return {
            students: [],
            recitals: [],
            lessons: [],
            is_loaded: false,
            silent_loaded: false,
            active_only: true
    }
    },

    methods: {
        onCellClick(data){
            this.$emit('cell-click', data)
        },

        async getStudents(){
            // Only query with year when not on the current year 
            //TODO: filter active button
            const {data} = await StudentAPI.getList(!this.active_only, this.year >= new Date().getUTCFullYear() ? null : this.year);
            this.student_ids = data;
            this.students = [];
            this.is_loaded = false;

            StudentAPI.getSimpleFor(this.student_ids).then(response =>{
                this.students = response.data
            })
        },

        async getRecitals(){
            const {data} = await RecitalsAPI.get();
            this.recitals = {};
            for (var i=0; i < data.length; i++){
                var time = new Date(data[i].monday).getTime() 
                this.recitals[time] = data[i];
            }
        },

         async getLessons(silent=false){
            this.silent_loaded = true;
            if (!silent){
                this.is_loaded = false;
            }
            
            LessonAPI.getFor(this.student_ids, this.first_week, this.last_week).then(res => {
                for (var li=0; li < res.data.length; li++){
                    var les = res.data[li];
                    var date = new Date(les.week_of);
                    if (this.lessons[les.student] == null){
                        this.$set(this.lessons, les.student, {});
                    }
                    this.$set(this.lessons[les.student] , date.getTime(), les);
                }
                this.silent_loaded = false;

            })
        },
        
    },

    computed: {
        weeks: function(){
            return [...dates.get_all_mondays(this.year)]
        },

        first_week: function(){
            var week = this.weeks[0]
            return week.getUTCFullYear() + '-' + (1 + week.getUTCMonth()) + '-' + week.getUTCDate();
        },

        last_week: function(){
            var week = this.weeks[this.weeks.length - 1]
            return week.getUTCFullYear() + '-' + (1 + week.getUTCMonth()) + '-' + week.getUTCDate();
        },

        sorted_students: function(){
            if (this.is_loaded == false){
                return []
            }else{
                return _.orderBy(this.students, ['last_name', 'first_name', 'id']); 
            }
        }
    },

    watch: {
      year: {
        immediate: true, 
        async handler () {
          await this.getStudents()
          await this.getLessons()
          this.is_loaded = true
        }
      },

      active_only: {
        immediate: true, 
        async handler () {
          await this.getStudents()
          await this.getLessons()
          this.is_loaded = true
        }
      }
    },

    created: async function(){
        this.getRecitals();
        await this.getStudents();
        if (this.lessons){
            await this.getLessons();
        }
        this.is_loaded = true
    },
}
</script>

<style>

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  border-radius: 0.5em;
 
}

.chartTable{
    font-size: auto;
    line-height: 1.0;
}

.cellDate{
    font-size: 0.5vw;
    width: 1.8%;
}

.cellName{
    font-size: 0.5vw;
    white-space: nowrap; 
    text-align: right
 }

/* Adjust me for star size */ 
.chartCell{
    font-size: 0.9vmax;
}


.chartItem:hover {
  background-color: lightgray
}
.star-container {
  text-align: center;
  padding-top: 1px;
  padding-bottom: 1px;
}

.star-container .fa-star:hover,
.star-container .fa-times:hover,
.star-container .fa-clock-o:hover,
.star-container .fa-circle:hover,
.star-selected {
  border: 1px dashed black;
  border-radius: 7px;
  cursor: pointer;
  opacity: 1;
}

.time-selected {
  border: 2px dashed black;
  border-radius: 7px;
  cursor: pointer;
}

.star-gold {
  color: gold;
}

.star-silver {
  color: silver;
}

.star-red {
  color: red;
}

.star-cross {
  color: grey;
}

.star-invisible{
  color: white;
  opacity: 0;
}

.star-times{
  color: #252525;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .75s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>